import Vue from 'vue'
import VueI18n from 'vue-i18n'
import jaJP from '@/locales/ja-JP.json'
import enUS from '@/locales/en-US.json'
import ptPT from '@/locales/pt-PT.json'
import frFR from '@/locales/fr-FR.json'
import itIT from '@/locales/it-IT.json'
import nlNL from '@/locales/nl-NL.json'
import deDE from '@/locales/de-DE.json'

Vue.use(VueI18n)

const defaultLang = getDefaultLang();
const Message = {
  'en-US': enUS,
  'ja-JP': jaJP,
  'pt-PT': ptPT,
  'fr-FR': frFR,
  'it-IT':itIT,
  'nl-NL':nlNL,
  'de-DE':deDE
}

const i18n = new VueI18n({
  locale: defaultLang || 'en-US',
  messages: Message,
  fallbackLocale: 'en-US',
  silentFallbackWarn: true
})

/**
 * 获取默认语言
 * @returns {string|string}
 */
export function getDefaultLang() {
  const lang = localStorage.getItem('lang');
  // return 'en-US';
  return lang || 'en-US';
}

/**
 * 获取语言下的文本
 * @param text
 * @param lang
 */
export function getLangMessage(text, lang = '') {
  const nextLang = lang || getDefaultLang();

  return Message[nextLang][text] || text;
}

export default i18n